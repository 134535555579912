import {
  GET_TRANSACTION_HISTORY,
  SET_ORDERS_PROP,
  IMPORT_BASKET,
  LIST_PAYMENT_CARDS,
  ADD_PAYMENT_CARD,
  REMOVE_PAYMENT_CARD,
  CREATE_ORDER,
  GET_ORDER_HISTORY,
  ADD_SCANNED_CARD,
  SET_DELIVERY_ADDRESS,
  POSTCODE_CHECK,
  SET_POSTCODE_DATA,
  GET_NEAREST_LOCATION,
  LOCATION_CODE_CHECK,
  SET_LOCATION_CODE_DATA,
  ADD_PICKUP_POINT,
  SET_PICK_UP_POINT,
  ADD_DELIVERY_ADDRESS,
  REMOVE_DELIVERY_ADDRESS,
  CHECK_CANCEL_ORDER,
  STORE_ITEM_WEB,
  UPDATE_PAYMENT_INFO,
  SEND_GIFT_VOUCHER,
  REDEEM_GIFT_VOUCHER,
  CLEAR_GIFT_VOUCHER_DATA,
  GET_SENT_GIFT_VOUCHERS,
  RESEND_GIFT_VOUCHER,
  UPDATE_GIFT_VOUCHER,
  JOIN_TABLE,
  GET_MY_TABLE,
  GET_MYCHECK_URL,
  SET_TABLE_DATA,
  GET_TABLE_APPLIED_VOUCHERS,
  SET_TABLE_APPLIED_VOUCHERS,
  LEAVE_TABLE,
  ACCEPT_USER,
  APPLY_VOUCHER,
  DISMISS_USER,
  REMOVE_VOUCHER,
  VALIDATE_VOUCHER,
  SET_VALIDATED_VOUCHER,
  SET_APPLIED_VOUCHERS_MODAL,
  SET_PENDING_VOUCHERS,
  REDEEM_FEEDBACK_CODE,
  GET_ACCESS_TOKEN,
  SET_ACCESS_TOKEN,
  SET_USER_CARDS,
  AUTHENTICATION_RESULT_VIEW_3DS,
  GLOBALPAY_ORDER_PAYMENT,
  CHECK_ORDER,
  RELEASE_VOUCHER,
  SET_CARD_TOKEN,

} from './constants';
import { UPDATE_PROFILE, GET_PROFILE } from '../constants';
import { take, call, put, select, delay, takeLatest } from 'redux-saga/effects';
import { loading } from '../common/sagas';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { getMyTable, showToast, getTableAppliedVouchers, applyVoucher } from '../actions';
import { isDefined, forwardTo, isWeb } from '../../lib/utils';
import { translateSaga } from '../common/sagas';
import { GET_BALANCE, GET_VOUCHERS } from '../profile/constants';
import { LOADING, SET_COMMON_MODAL, SET_COMMON_PROP } from '../common/constants';
import { SET_RESTAURANT_PROP } from '../restaurants/constants';
import asyncStorage from '../../lib/asyncStorage';
import { getEnv } from '../../lib/env';
import { checkSnoozedTimes, formatDataForTime } from '../../screens/clickAndCollect';
import moment from '../../lib/moment';
import 'moment-timezone'
import { Browser } from '@capacitor/browser';
/* transaction history Saga */
export const getTransactionHistoryFlow = function* () {
  const { hasOrdering, hasLoyalty } = getConfig().appType;
  while (true) {
    yield take(GET_TRANSACTION_HISTORY);
    yield call(loading, function* () {
      // call transaction and order history

      const history = yield call(api.getHistory);
      yield put({ type: SET_ORDERS_PROP, key: 'history', value: history });
    });
  }
};

export const importBasket = function* () {
  while (true) {
    yield take(IMPORT_BASKET);
    yield call(Basket.import);
  }
};

export const getPaymentCardsFlow = function* () {
  while (true) {
    yield take(LIST_PAYMENT_CARDS);
    yield call(loading, function* () {
      const cards = yield call(api.getPaymentCards);
      yield put({ type: SET_ORDERS_PROP, key: 'cards', value: cards });
    });
  }
};

export const addPaymentCardsFlow = function* () {
  while (true) {
    const action = yield take(ADD_PAYMENT_CARD);
    const { name, options } = action;
    let result = null;
    if (getConfig().payment === 'judopay') {
      options['name'] = name;
      yield call(loading, function* () {
        try {
          const savedCards = yield call(api.saveJudoPayCard, options);
          yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
          yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

          yield put({ type: GET_PROFILE });
          if (options.cb) {
            yield call(options.cb);
          }
        } catch (e) {
          yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
          throw e;
        }
      });
    } else {
      // if (options.stripe) {
      //   result = yield call(options.stripe.createToken);
      // }
      // yield call(loading, function* () {
      //   if (options.stripe) {
      //     try {
      //       if (result.error) {
      //         yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
      //       } else {
      //         const payment_token = result.token.id;

      //         const savedCards = yield call(api.addPaymentCard, { payment_token, name });
      //         yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
      //         yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

      //         //everytime when user add card that card will be default card. Because of that we call get profile
      //         yield put({ type: GET_PROFILE });

      //         if (options.cb) {
      //           yield call(options.cb);
      //         }
      //       }
      //     } catch (e) {
      //       yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
      //       throw e;
      //     }
      //   }
      // });
    }
  }
};

export const addScannedCard = function* () {
  while (true) {
    const action = yield take(ADD_SCANNED_CARD);
    yield call(loading, function* () {
      const { options, payment_token, name } = action;
      const savedCards = yield call(api.addPaymentCard, { payment_token, name });
      yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
      yield put(showToast('Card added successfully', 'success'));

      //everytime when user add card that card will be default card. Because of that we call get profile
      yield put({ type: GET_PROFILE });
      if (options.cb) {
        yield call(options.cb);
      }
    });
  }
};

export const removePaymentCardsFlow = function* () {
  while (true) {
    const action = yield take(REMOVE_PAYMENT_CARD);
    yield call(loading, function* () {
      const { cardData, options } = action;

      const data = {
        payment_token: cardData.token,
        pmt: cardData.pmt
      };

      const result = yield call(api.removePaymentCard, data);
      if (result.error) {
        yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
      } else {
        const accessToken = yield call(api.getAccessToken);
        yield put({ type: SET_USER_CARDS, cards: accessToken.data.payment_methods });
        yield put({ type: SET_CARD_TOKEN, card_token: accessToken.data.card_token });
        yield put({ type: SET_ACCESS_TOKEN, token: accessToken.data.token });

        yield put(showToast(yield call(translateSaga, 'Card removed successfully'), 'success'));
      }

    });
  }
};

const checkIntentResult = function* (intentResult) {
  // Stripe.checkIntentResult i maybe already called

  const status = isDefined(intentResult.isValid)
    ? intentResult
    : yield call(Stripe.checkIntentResult, intentResult);
  if (status.isValid) {
    yield put(showToast(status.message, 'success'));
  } else {
    yield put(showToast(status.message, 'danger'));
  }
  return status;
};

const updatePaymentInfo = function* (intentResult, paymentType, createdOrder) {
  const paymentInfo = {
    intentResult,
    paymentMethod: paymentType,
    orderId: createdOrder.id,
  };
  yield put({ type: UPDATE_PAYMENT_INFO, paymentInfo });
};

const resetBasket = (giftVoucherData) => {
  Basket.reset();
  if (!giftVoucherData) {
    forwardTo('/order-completed', { completedOrder: true });
  } else {
  }
};
export const checkOrderFlow = function* () {
  while (true) {
    const { data } = yield take(CHECK_ORDER)
    yield put({ type: SET_COMMON_PROP, key: 'checkOrderLoading', value: true });
    const result = yield call(api.checkOrder, data);
    yield delay(1000);
    yield put({ type: SET_COMMON_PROP, key: 'checkOrderLoading', value: false });
    Basket.addDiscounts(result.data.discounts)
    if (result.data.vouchers.length > 0) {
      const selecetedVoucher = result.data.vouchers[0]
      if (selecetedVoucher.cost == 0) {
        yield put({ type: RELEASE_VOUCHER, data: selecetedVoucher })
        yield put(showToast(getConfig().general.voucherRemovedFromBasketMessage, 'warning'));
      } else {
        const profileVouchers = yield select((store) => store.profile.vouchers);
        const appliedVoucher = profileVouchers.find((voucher) => voucher.VoucherID === selecetedVoucher.id);

        Basket.addVoucher(appliedVoucher, selecetedVoucher, false)
      }

    } else {
      yield put({ type: RELEASE_VOUCHER, data: selecetedVoucher })

    }
    Basket.updateItemPrices(result.data.items)

  }

}


export function* createOrderFlow() {
  yield takeLatest(CREATE_ORDER, createOrderFlowHandler);
}

export const createOrderFlowHandler = function* (action) {
  const { paymentType, paymentWebType, cb } = action
  yield call(loading, function* () {
    const store = yield select()
    yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: false });
    let orderData = Basket.parseBasketData(paymentType, paymentWebType);
    orderData.pmt = Basket.getPMT()
    orderData.token = store.orders.token
    orderData.card_token = store.orders.card_token

    //create order and init stripe intent
    const result = yield call(api.createOrder, orderData);
    yield delay(5000);
    const orderHistory = yield call(api.getOrderHistory);
    yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
    if (result.data?.error || result.error) {
      if (result.data?.type === 'collection-time') {
        const restaurants = yield call(api.getRestaurants);
        yield put({
          type: SET_RESTAURANT_PROP,
          key: 'restaurants',
          value: restaurants,
        });
        const selectedRestaurant = restaurants.filter(restaurant => restaurant.id == Basket.getRestaurant().id)
        const minDT = moment().tz(getConfig().timezone)
        if (selectedRestaurant[0] && isDefined(selectedRestaurant[0].order_slot_lead_time)) {
          minDT.add(selectedRestaurant[0].order_slot_lead_time, 'minutes');
        }
        const orderedSlot = moment(orderData.collection_time).tz(getConfig().timezone).format('HH:mm')
        const nextAvailable = formatDataForTime(
          selectedRestaurant[0],
          minDT,
          selectedRestaurant[0].id,
          false,
          false,
          checkSnoozedTimes(selectedRestaurant[0], 'collection')
        ).filter(el => !el.disabled && moment(moment().hours(el.value.split(':')[0]).minutes(el.value.split(':')[1])).unix() > moment(moment().hours(orderedSlot.split(':')[0]).minutes(orderedSlot.split(':')[1])).unix())
        if (nextAvailable.length > 0) {
          yield put({ type: SET_ORDERS_PROP, key: 'changeCollectionTimeModalTime', value: nextAvailable[nextAvailable.length == 1 ? 0 : 1].text });
          yield put({ type: SET_ORDERS_PROP, key: 'changeCollectionTimeModalOpen', value: true });
        }
        else {
          Basket.reset()
          forwardTo('/order')
          yield put(showToast(getConfig().general.noMoreSlotsMessage, 'warning'));
          yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
        }
      } else {
        yield put(showToast('Order create error', 'warning'));
        yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
        forwardTo('/order')

      }
    } else {


      if (result.data.order.status.toLowerCase() == 'captured') {
        yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
        yield call(forwardTo, '/order-completed', { completedOrder: true });
        Basket.reset()

      } else {
        const websiteUrl = getConfig()?.general?.websiteUrl || "https://fmapp.francomanca.co.uk";
        if (isWeb()) {
          yield call(forwardTo, '/order-completed', { completedOrder: true });

          try {
            const { checkVersion,
              getBrowserData,
              initiateAuthentication,
              AuthenticationSource,
              AuthenticationRequestType,
              MessageCategory,
              ChallengeRequestIndicator,
              ChallengeWindowSize,
            } = GlobalPayments.ThreeDSecure;

            const versionCheckData = yield call(checkVersion, `${getEnv('BASE_URL')}/order/check_version_3ds`, {
              challengeNotificationUrl: `${websiteUrl}/payment-result-view`,
              threeDsMethodReturnUrl: `${websiteUrl}/payment-result-view`,
              token: store.orders.token,
              pmt: Basket.getPMT(),
              amount: Math.round(Basket.toCents(Basket.getTotal())),
              email: store.profile.profile.email,
              card: {
                reference: Basket.getPMT()
              },
              custom: {
                id: result.data.order.id
              },
              reference: `${store.profile.profile.email.replace(/['`{}=?^!#$%&*@+,|]/g, '_')}`
            })
            Basket.setGlobalpayAut(versionCheckData.serverTransactionId)
            Basket.setGlobalpayToken(store.orders.token)
            Basket.setGlobalpayCardToken(store.orders.card_token)

            if (versionCheckData.status === "NOT_ENROLLED" && versionCheckData.liabilityShift !== "YES") {
              yield put(showToast('Your payment has failed', 'warning'));
              yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
              return false;
            }

            if (versionCheckData.liabilityShift === "YES") {
              return true;
            }
            if (versionCheckData.response_data?.error_code || versionCheckData.error_code || versionCheckData.error) {
              yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
            }
            const authenticateData = yield call(initiateAuthentication, `${getEnv('BASE_URL')}/order/initiate_authentication_3ds`, {
              challengeNotificationUrl: `${websiteUrl}/payment-result-view`,
              challengeWindow: {
                windowSize: ChallengeWindowSize.Windowed600x400,
                displayMode: 'lightbox',
              },
              methodUrlComplete: true,
              card: {
                reference: Basket.getPMT()
              },
              custom: {
                merchantContactUrl: `${websiteUrl}/payment-result-view`,
                amount: Math.round(Basket.toCents(Basket.getTotal())),
                id: result.data.order.id
              },
              authenticationRequestType: AuthenticationRequestType.PaymentTransaction,
              serverTransactionId: versionCheckData.serverTransactionId,
              token: store.orders.token
            })
            if (authenticateData.error_code) {
              yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
            }
            const isAuthenticated = authenticateData.status === "SUCCESS_AUTHENTICATED"
            if (isAuthenticated) {
              const data = {
                id: result.data.order.id,
                pmt: Basket.getPMT(),
                pmt_selected: Basket.getPMTSelected(),
                pmt_single: Basket.getPMTSingle(),
                aut: versionCheckData.serverTransactionId,
                token: store.orders.token,
                card_token: store.orders.card_token

              }
              yield put({ type: GLOBALPAY_ORDER_PAYMENT, data })
              Basket.reset()

            }

          } catch (err) {
            yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
            if (err.message) {
              yield put(showToast(err.message, 'success', 'Payment failed'));

            } else {
              yield put(showToast('Your payment has failed', 'warning'));

            }

          }

        }
        else {
          yield call(Browser.removeAllListeners);
          const store = yield select()

          const data = {
            token: store.profile.auth.token,
            order_id: result.data.order.id,
            email: store.profile.profile.email
          }
          yield call(Browser.open, { url: `${websiteUrl}/native-checkout?data=${encodeURIComponent(JSON.stringify(data))}`, toolbarColor: "#ffffff", presentationStyle: 'popover', windowName: '_self' });
          Browser.addListener('browserFinished', () => {
            forwardTo('/order-completed', { completedOrder: true, removeLoader: true })
          });
        }
      }
    }


  });
};

export const updatePaymentInfoFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_PAYMENT_INFO);
    yield call(loading, function* () {
      const { paymentInfo } = action;
      const updatedOrder = yield call(api.updatePaymentInfo, paymentInfo);
      const store = yield select();
      let orderHistory = store.orders.orderHistory;
      const index = orderHistory.findIndex((i) => i.id === updatedOrder.id);
      if (index !== -1) {
        orderHistory[index] = updatedOrder;
      } else {
        orderHistory = [updatedOrder, ...orderHistory];
      }
      yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
    });
  }
};

const getOrderHistoryData = function* () {
  const orderHistory = yield call(api.getOrderHistory);
  yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
};

export const getOrderHistoryFlow = function* () {
  while (true) {
    const action = yield take(GET_ORDER_HISTORY);
    const loading = isDefined(action.loading) ? action.loading : true;

    if (loading) {
      yield call(loading, function* () {
        yield call(getOrderHistoryData);
      });
    } else {
      yield call(getOrderHistoryData);
    }
  }
};

export const addDeliveryAddressFlow = function* () {
  while (true) {
    const action = yield take(ADD_DELIVERY_ADDRESS);
    const { deliveryAddress, flag } = action;
    yield call(loading, function* () {
      try {
        const address = yield call(api.addDeliveryAdress, deliveryAddress);
        yield put({ type: SET_DELIVERY_ADDRESS, deliveryAddress: JSON.parse(address.config.data) });
        yield put(showToast(yield call(translateSaga, 'Address added successfully'), 'success'));
        yield put({ type: GET_PROFILE });
        if (!flag) {
          yield call(forwardTo, '/delivery-time');
        }
      } catch (e) {
        yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
      }
    });
  }
};

export const postCodeCheckFlow = function* () {
  while (true) {
    const action = yield take(POSTCODE_CHECK);
    const { postcode } = action;
    yield call(loading, function* () {
      try {
        const checkedCodeData = yield call(api.postCodeCheck, { postcode });
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
        if (checkedCodeData.data.length < 1) {
          yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
        }
      } catch (e) {
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
      }
    });
  }
};

export const getNearestLocationFlow = function* () {
  while (true) {
    const action = yield take(GET_NEAREST_LOCATION);
    const { postcode, charter_delivery } = action;
    yield call(loading, function* () {
      try {
        const checkedCodeData = yield call(api.getNearestLocation, { postcode, charter_delivery });
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
        if (checkedCodeData.data.length < 1) {
          //yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
        }
      } catch (e) {
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
      }
    });
  }
};

export const locationCodeCheckFlow = function* () {
  while (true) {
    const action = yield take(LOCATION_CODE_CHECK);
    const { locationCode } = action;
    try {
      const checkedLocationCodeData = yield call(api.locationCodeCheck, {
        location_code: locationCode,
      });
      yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData });
    } catch (e) {
      yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData: { data: [] } });
    }
  }
};

export const addPickupPointFlow = function* () {
  while (true) {
    const action = yield take(ADD_PICKUP_POINT);
    const { pickUpPoint, code } = action;
    yield call(loading, function* () {
      try {
        const point = yield call(api.addPickupPoint, { restaurant_id: pickUpPoint, code });
        let parsedData = JSON.parse(point.config.data);
        yield put({ type: SET_PICK_UP_POINT, pickUpPoint: parsedData.code });
        yield put(
          showToast(yield call(translateSaga, 'Pickup point added successfully'), 'success'),
        );
        yield put({ type: GET_PROFILE });
        yield call(forwardTo, '/delivery-time');
      } catch (e) {
        yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
      }
    });
  }
};

export const removeDeliveryAddressFlow = function* () {
  while (true) {
    const action = yield take(REMOVE_DELIVERY_ADDRESS);
    yield put({ type: SET_ORDERS_PROP, key: 'removeAddressModal', value: false });
    yield call(loading, function* () {
      const { index, order_type } = action;
      const data = {
        id: index,
        orderType: order_type,
      };
      const result = yield call(api.removeDeliveryAddress, data);
      if (result.error) {
        yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
      } else {
        yield put(
          showToast(yield call(translateSaga, 'Delivery address removed successfully'), 'success'),
        );
      }
      yield put({ type: GET_PROFILE });
    });
  }
};

export const checkCancelOrderFlow = function* () {
  while (true) {
    const action = yield take(CHECK_CANCEL_ORDER);
    yield put({ type: SET_ORDERS_PROP, key: 'cancelOrderModal', value: false });
    yield call(loading, function* () {
      const { orderId, restaurantId } = action;
      const data = {
        order_id: orderId,
        restaurant_id: restaurantId,
      };
      yield call(api.cancelOrder, data);
      yield put(showToast(yield call(translateSaga, 'Order successfully refunded'), 'success'));
    });
  }
};

export const storeWebItemFlow = function* () {
  while (true) {
    const action = yield take(STORE_ITEM_WEB);
    yield put({ type: SET_ORDERS_PROP, key: 'storedItemWeb', value: action.item });

    if (action.cb) {
      yield call(action.cb);
    }
  }
};
export const sendGiftVoucherFlow = function* () {
  while (true) {
    const action = yield take(SEND_GIFT_VOUCHER);
    yield call(loading, function* () {
      const result = yield call(api.sendGiftVoucher, action.data);
      if (!result.error) {
        forwardTo('/gift-vouchers', { giftVoucherSent: true });
        yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
        yield put({ type: GET_SENT_GIFT_VOUCHERS });
      }
    });
  }
};
export const resendGiftVoucherFlow = function* () {
  while (true) {
    const action = yield take(RESEND_GIFT_VOUCHER);
    yield call(loading, function* () {
      const result = yield call(api.resendGiftVoucher, action.data);
      if (!result.error) {
        forwardTo('/gift-vouchers', { giftVoucherRedeemed: true });
        yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
        yield put({ type: GET_SENT_GIFT_VOUCHERS });
      }
    });
  }
};
export const updateGiftVoucherFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_GIFT_VOUCHER);
    yield call(loading, function* () {
      const result = yield call(api.updateGiftVoucher, action.data);
      if (!result.error) {
        forwardTo('/gift-vouchers', { giftVoucherUpdated: true });
        yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
        yield put({ type: GET_SENT_GIFT_VOUCHERS });
      }
    });
  }
};
export const redeemGiftVoucherFlow = function* () {
  while (true) {
    const action = yield take(REDEEM_GIFT_VOUCHER);
    yield call(loading, function* () {
      const result = yield call(api.redeemGiftVoucher, action.data);
      if (!result.error) {
        // forwardTo('/gift-vouchers', { giftVoucherRedeemed: true });
        yield put({
          type: 'SET_REDEEMED_GIFT_VOUCHER',
          value: result.data,
        });
      }
    });
  }
};
export const getSentGiftVouchersFlow = function* () {
  while (true) {
    const action = yield take(GET_SENT_GIFT_VOUCHERS);
    yield call(loading, function* () {
      let result = yield call(api.getSentGiftVouchers);
      result.sort((a, b) => b.id - a.id);
      yield put({
        type: 'SET_SENT_GIFT_VOUCHERS',
        value: result,
      });
    });
  }
};
export const redeemFeedbackCodeFlow = function* () {
  while (true) {
    const action = yield take(REDEEM_FEEDBACK_CODE);
    yield call(loading, function* () {
      try {
        let result = yield call(api.redeemFeedbackCode, {
          feedback_code: action.feedback_code,
        });
        yield put({ type: GET_VOUCHERS });
        yield put({ type: GET_BALANCE });
        yield delay(500);
        yield put({ type: LOADING, loading: false });
        yield put({ type: SET_COMMON_PROP, key: 'stampsModalContent', value: result });
      } catch (err) { }
    });
  }
};
export const joinTableFlow = function* () {
  while (true) {
    const action = yield take(JOIN_TABLE);
    yield call(loading, function* () {
      yield put({type:SET_COMMON_PROP,key:'joinTableStarted',value:true})
      try {
        let result = yield call(api.joinTable, {
          location: action.data.location,
          token: action.data.token,
        });
      } catch (error) {
        console.log("Error joining table");
      }
      
      const tableData = yield call(getMyTablePollingFlow);
      if (tableData) {
        localStorage.removeItem('cancelPool')
        localStorage.removeItem('currentTry')
        yield put({type:SET_COMMON_PROP,key:'joinTableStarted',value:false})

        forwardTo('/table');
        
      } else {
        const error = yield call(asyncStorage.getItem, 'tableError');
        yield put({type:SET_COMMON_PROP,key:'joinTableStarted',value:false})

        if (error) {
          yield put(showToast(error, 'error'));
          yield call(asyncStorage.removeItem, 'tableError');
        } else {
          yield put(
            showToast(
              'Could not connect to the table. Please try again in a couple of minutes',
              'error',
            ),
          );
        }
        if (action?.data?.errorCb) {
          action.data.errorCb();
        }
      }
    });
  }
};

export const getMyTablePollingFlow = async (counter = 0) => {
  const config = getConfig().general;
  const tableCodeError = localStorage.getItem('tableError');
  const cancelPool = localStorage.getItem('cancelPool')

  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.getMyTable(false);
      return resolve(true);
    } catch (e) {
      if (counter == config.joinTableConfig.joinTableCounter || tableCodeError || cancelPool) {
        if (cancelPool) {
          localStorage.removeItem('cancelPool')
          localStorage.removeItem('currentTry')
        }
        resolve(false);
      } else {
        setTimeout(() => {
          resolve(getMyTablePollingFlow(counter + 1));
        }, config.joinTableConfig.joinTableRefreshSeconds * 1000);
      }
    }
  });
};
export const getMyTableFlow = function* () {
  while (true) {
    const action = yield take(GET_MY_TABLE);
    const disableRedirect = action.disableRedirect;
    yield call(loading, function* () {
      try {
        let result = yield call(api.getMyTable);
        if (!getConfig().flags.useOldFirebaseStructure) {
          if (result.data.customers) {
            const pendingUsers = result.data.customers.filter(customer => customer.is_pending)
            yield put({ type: SET_COMMON_PROP, key: `pendingUsers`, value: pendingUsers });
          }
        }
        if (!action.fromHomepage && !disableRedirect) {
          yield call(forwardTo, '/table');
        }
        const isTableOpened = yield call(asyncStorage.getItem, 'tableOpened');
        if (!isTableOpened && window.location.pathname == '/table') {
          yield call(asyncStorage.setItem, 'tableOpened', true);
          yield put({ type: SET_COMMON_MODAL, modal: 'joinTableModalOpen', value: true });
        }
        yield put({ type: SET_TABLE_DATA, value: result.data });
        yield put({ type: GET_TABLE_APPLIED_VOUCHERS, tableId: result.data.id });
        if (action && action.cb) {
          action.cb();
        }
      } catch (err) {
        if (!disableRedirect) {
          yield call(forwardTo, '/homepage');
        }
        yield put({ type: SET_TABLE_DATA, value: {} });
        if (!action.fromHomepage && action.fromHomepage !== undefined) {
          yield put(showToast(err.response?.data?.error, 'error'));
        }
      }
    });
  }
};
export const getTableAppliedVouchersFlow = function* () {
  while (true) {
    const action = yield take(GET_TABLE_APPLIED_VOUCHERS);
    yield call(loading, function* () {
      try {
        let result = yield call(api.getTableAppliedVouchers, { id: action.tableId });
        yield put({ type: SET_TABLE_APPLIED_VOUCHERS, value: result.data });
      } catch (err) {
        console.log(err);
      }
    });
  }
};
export const leaveTableFlow = function* () {
  while (true) {
    const action = yield take(LEAVE_TABLE);

    yield call(loading, function* () {
      try {
        let result = yield call(api.leaveTable, { id: action.tableId });
        yield put({ type: SET_COMMON_PROP, key: 'deletingUserPending', value: true });

        yield delay(5000);
        yield call(forwardTo, '/homepage');
        yield call(asyncStorage.removeItem, 'tableOpened');

        yield put({ type: SET_COMMON_PROP, key: 'deletingUserPending', value: false });

        yield put({ type: SET_TABLE_APPLIED_VOUCHERS, value: [] });
        yield put({ type: SET_TABLE_DATA, value: {} });
      } catch (err) {
        console.log(err);
      }
    });
  }
};
export const applyVoucherFlow = function* () {
  while (true) {
    const action = yield take(APPLY_VOUCHER);
    yield call(loading, function* () {
      try {
        let result = yield call(api.applyVoucher, action.data);
        yield put({ type: SET_PENDING_VOUCHERS, value: [action.data.code] });
      } catch (err) {
        if (err.response?.data?.error) {
          yield put(showToast(err.response?.data?.error, 'success'));
          // yield put({ type: GET_TABLE_APPLIED_VOUCHERS, tableId: action.data.id })
          // yield put({ type: SET_PENDING_VOUCHERS, value: [] })
        }
      }
    });
  }
};
export const removeVoucherFlow = function* () {
  while (true) {
    const action = yield take(REMOVE_VOUCHER);
    yield call(loading, function* () {
      let result = yield call(api.removeVoucher, action.data);
    });
  }
};
export const releaseVoucherFlow = function* () {
  while (true) {
    const action = yield take(RELEASE_VOUCHER);
    let result = yield call(api.releaseVoucher, action.data);
    Basket.clearAllDiscounts(false, true);
  }
};
export const validateVoucherFlow = function* () {
  while (true) {
    const action = yield take(VALIDATE_VOUCHER);
    yield call(loading, function* () {
      let result = yield call(api.validateVoucher, action.data);
      yield put({ type: SET_VALIDATED_VOUCHER, value: true });
      yield put({ type: APPLY_VOUCHER, data: action.data });
    });
  }
};
export const globalPayOrderPaymentFlow = function* () {
  while (true) {
    try {
      const action = yield take(GLOBALPAY_ORDER_PAYMENT);
      const result = yield call(api.globalpayOrderPayment, action.data)
      yield call(getOrderHistoryData);
      yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
      yield put(showToast(yield call(translateSaga, 'Your payment has been successfully processed'), 'success'));
    } catch (err) {
      yield put({ type: SET_COMMON_PROP, key: 'paymentProcessCompleted', value: true });
      yield put(showToast(yield call(translateSaga, 'Your payment has failed'), 'success'));
    }


  }
};

export const acceptUserFlow = function* () {
  while (true) {
    const action = yield take(ACCEPT_USER);

    yield call(loading, function* () {
      try {
        let result = yield call(api.acceptUser, action.data);
        yield delay(3000);
        yield put({ type: GET_MY_TABLE });
      } catch (err) {
        console.log(err);
      }
    });
  }
};
export const dismissUserFlow = function* () {
  while (true) {
    const action = yield take(DISMISS_USER);

    yield call(loading, function* () {
      try {
        let result = yield call(api.dismissUser, action.data);
        console.log('dismissUser');
        yield put({ type: GET_MY_TABLE });
      } catch (err) {
        console.log(err);
      }
    });
  }
};

export const getAccessTokenFlow = function* () {
  while (true) {
    yield take(GET_ACCESS_TOKEN);
    yield put({ type: LOADING, loading: true });
    yield call(loading, function* () {
      let result = yield call(api.getAccessToken);
      const { payment_methods, token, card_token } = result.data
      yield put({ type: SET_ACCESS_TOKEN, value: token });
      yield put({ type: SET_USER_CARDS, value: payment_methods });
      yield put({ type: SET_CARD_TOKEN, value: card_token });
      yield put({ type: LOADING, loading: false });
    })


  }
}

export const getMycheckUrlFlow = function* () {
  while (true) {
    const action = yield take(GET_MYCHECK_URL);
    yield call(loading, function* () {
      try {
        let result = yield call(api.getMycheckUrl);
        const menuUrl = result['menuUrl'];
        const locationTarget = result['target'];
        setTimeout(() => {
          window.open(menuUrl, locationTarget, 'location=no');
        });
      } catch {
        yield put(
          showToast(
            'There was an error connecting you to our ordering service. Please try again later.',
            'warning',
            'OH NO!',
          ),
        );
      }
    });
  }
};
